
.m-stack {
	display: block;
	width: 100%;
	height: 100%; }
	.m-stack.m-stack--hor > .m-stack__item {
	  display: block; }
	  .m-stack.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		display: block;
		height: auto; }
	.m-stack.m-stack--ver > .m-stack__item {
	  display: block;
	  height: auto; }
  
  .m-stack.m-stack--general {
	display: table;
	table-layout: fixed; }
	.m-stack.m-stack--general.m-stack--fluid {
	  table-layout: auto; }
	.m-stack.m-stack--general.m-stack--inline {
	  width: auto; }
	.m-stack.m-stack--general.m-stack--hor > .m-stack__item {
	  display: table-row;
	  vertical-align: top;
	  height: 1px; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item > .m-stack__demo-item {
		height: 100%; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		height: auto; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--fit {
		line-height: 0; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--left {
		text-align: left; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--right {
		text-align: right; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--center {
		text-align: center; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--top {
		vertical-align: top; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--middle {
		vertical-align: middle; }
	  .m-stack.m-stack--general.m-stack--hor > .m-stack__item.m-stack__item--bottom {
		vertical-align: bottom; }
	.m-stack.m-stack--general.m-stack--ver.m-stack--demo {
	  height: 80px; }
	.m-stack.m-stack--general.m-stack--ver > .m-stack__item {
	  display: table-cell;
	  vertical-align: top;
	  height: 100%; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--fluid {
		width: 100%; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--fit {
		line-height: 0; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--left {
		text-align: left; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--right {
		text-align: right; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--center {
		text-align: center; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--top {
		vertical-align: top; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--middle {
		vertical-align: middle; }
	  .m-stack.m-stack--general.m-stack--ver > .m-stack__item.m-stack__item--bottom {
		vertical-align: bottom; }
  
  .m-stack.m-stack--flex-general {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column; }
	.m-stack.m-stack--flex-general.m-stack--ver > .m-stack__item.m-stack__item--first {
	  display: block;
	  -webkit-box-ordinal-group: 2;
	  -ms-flex-order: 1;
	  order: 1; }
	.m-stack.m-stack--flex-general.m-stack--ver > .m-stack__item.m-stack__item--last {
	  display: block;
	  -webkit-box-ordinal-group: 3;
	  -ms-flex-order: 2;
	  order: 2; }
  
  @media (min-width: 1025px) {
	.m-stack.m-stack--desktop {
	  display: table;
	  table-layout: fixed; }
	  .m-stack.m-stack--desktop.m-stack--fluid {
		table-layout: auto; }
	  .m-stack.m-stack--desktop.m-stack--inline {
		width: auto; }
	  .m-stack.m-stack--desktop.m-stack--hor > .m-stack__item {
		display: table-row;
		vertical-align: top;
		height: 1px; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item > .m-stack__demo-item {
		  height: 100%; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		  height: auto; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--desktop.m-stack--hor > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	  .m-stack.m-stack--desktop.m-stack--ver.m-stack--demo {
		height: 80px; }
	  .m-stack.m-stack--desktop.m-stack--ver > .m-stack__item {
		display: table-cell;
		vertical-align: top;
		height: 100%; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--fluid {
		  width: 100%; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--desktop.m-stack--ver > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	.m-stack.m-stack--flex-desktop {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column; }
	  .m-stack.m-stack--flex-desktop.m-stack--ver > .m-stack__item.m-stack__item--first {
		display: block;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1; }
	  .m-stack.m-stack--flex-desktop.m-stack--ver > .m-stack__item.m-stack__item--last {
		display: block;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2; } }
  
  @media (min-width: 769px) {
	.m-stack.m-stack--desktop-and-tablet {
	  display: table;
	  table-layout: fixed; }
	  .m-stack.m-stack--desktop-and-tablet.m-stack--fluid {
		table-layout: auto; }
	  .m-stack.m-stack--desktop-and-tablet.m-stack--inline {
		width: auto; }
	  .m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item {
		display: table-row;
		vertical-align: top;
		height: 1px; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item > .m-stack__demo-item {
		  height: 100%; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		  height: auto; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--hor > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	  .m-stack.m-stack--desktop-and-tablet.m-stack--ver.m-stack--demo {
		height: 80px; }
	  .m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item {
		display: table-cell;
		vertical-align: top;
		height: 100%; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--fluid {
		  width: 100%; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	.m-stack.m-stack--flex-desktop-and-tablet {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column; }
	  .m-stack.m-stack--flex-desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--first {
		display: block;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1; }
	  .m-stack.m-stack--flex-desktop-and-tablet.m-stack--ver > .m-stack__item.m-stack__item--last {
		display: block;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2; } }
  
  @media (min-width: 769px) and (max-width: 1024px) {
	.m-stack.m-stack--tablet {
	  display: table;
	  table-layout: fixed; }
	  .m-stack.m-stack--tablet.m-stack--fluid {
		table-layout: auto; }
	  .m-stack.m-stack--tablet.m-stack--inline {
		width: auto; }
	  .m-stack.m-stack--tablet.m-stack--hor > .m-stack__item {
		display: table-row;
		vertical-align: top;
		height: 1px; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item > .m-stack__demo-item {
		  height: 100%; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		  height: auto; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--tablet.m-stack--hor > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	  .m-stack.m-stack--tablet.m-stack--ver.m-stack--demo {
		height: 80px; }
	  .m-stack.m-stack--tablet.m-stack--ver > .m-stack__item {
		display: table-cell;
		vertical-align: top;
		height: 100%; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--fluid {
		  width: 100%; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--tablet.m-stack--ver > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	.m-stack.m-stack--flex-tablet {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column; }
	  .m-stack.m-stack--flex-tablet.m-stack--ver > .m-stack__item.m-stack__item--first {
		display: block;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1; }
	  .m-stack.m-stack--flex-tablet.m-stack--ver > .m-stack__item.m-stack__item--last {
		display: block;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2; } }
  
  @media (max-width: 1024px) {
	.m-stack.m-stack--tablet-and-mobile {
	  display: table;
	  table-layout: fixed; }
	  .m-stack.m-stack--tablet-and-mobile.m-stack--fluid {
		table-layout: auto; }
	  .m-stack.m-stack--tablet-and-mobile.m-stack--inline {
		width: auto; }
	  .m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item {
		display: table-row;
		vertical-align: top;
		height: 1px; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item > .m-stack__demo-item {
		  height: 100%; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		  height: auto; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--hor > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	  .m-stack.m-stack--tablet-and-mobile.m-stack--ver.m-stack--demo {
		height: 80px; }
	  .m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item {
		display: table-cell;
		vertical-align: top;
		height: 100%; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--fluid {
		  width: 100%; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	.m-stack.m-stack--flex-tablet-and-mobile {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column; }
	  .m-stack.m-stack--flex-tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--first {
		display: block;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1; }
	  .m-stack.m-stack--flex-tablet-and-mobile.m-stack--ver > .m-stack__item.m-stack__item--last {
		display: block;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2; } }
  
  @media (max-width: 768px) {
	.m-stack.m-stack--mobile {
	  display: table;
	  table-layout: fixed; }
	  .m-stack.m-stack--mobile.m-stack--fluid {
		table-layout: auto; }
	  .m-stack.m-stack--mobile.m-stack--inline {
		width: auto; }
	  .m-stack.m-stack--mobile.m-stack--hor > .m-stack__item {
		display: table-row;
		vertical-align: top;
		height: 1px; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item > .m-stack__demo-item {
		  height: 100%; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--fluid {
		  height: auto; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--mobile.m-stack--hor > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	  .m-stack.m-stack--mobile.m-stack--ver.m-stack--demo {
		height: 80px; }
	  .m-stack.m-stack--mobile.m-stack--ver > .m-stack__item {
		display: table-cell;
		vertical-align: top;
		height: 100%; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--fluid {
		  width: 100%; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--fit {
		  line-height: 0; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--left {
		  text-align: left; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--right {
		  text-align: right; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--center {
		  text-align: center; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--top {
		  vertical-align: top; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--middle {
		  vertical-align: middle; }
		.m-stack.m-stack--mobile.m-stack--ver > .m-stack__item.m-stack__item--bottom {
		  vertical-align: bottom; }
	.m-stack.m-stack--flex-mobile {
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column; }
	  .m-stack.m-stack--flex-mobile.m-stack--ver > .m-stack__item.m-stack__item--first {
		display: block;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1; }
	  .m-stack.m-stack--flex-mobile.m-stack--ver > .m-stack__item.m-stack__item--last {
		display: block;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2; } }
  
  .m-stack--demo.m-stack--ver .m-stack__item,
  .m-stack--demo.m-stack--hor .m-stack__demo-item {
	padding: 10px;
	border: 1px solid #eee;
	background: #fbfbfb;
	font-weight: 500; }
  