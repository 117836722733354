/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.table th,
.table td {
  vertical-align: middle;
}

$reap-color: #005e85;
$epic-color: #3092dc;
;

html[data-theme='reap'] {

  .label-primary,
  .badge-primary {
    background-color: $reap-color;
  }

  .agreementRelated[aria-expanded="true"] {
    .panel-heading {
      color: #fff;
      background-color: $reap-color;
      border-color: $reap-color;
    }
  }

  .others[aria-expanded="true"] {
    .panel-heading {
      color: #fff;
      background-color: $reap-color;
      border-color: $reap-color;
    }
  }

  .product-price {
    background-color: $reap-color;
  }

  .page-item.active .page-link {
    background-color: $reap-color;
    border-color: $reap-color;
  }

  .includesAndReferences[aria-expanded=true] .panel-heading,
  .referencesAndTypes[aria-expanded=true] .panel-heading,
  .agreementsAndProperty[aria-expanded=true] .panel-heading,
  .othersProject[aria-expanded=true] .panel-heading    {
    color: #fff;
    background-color: $reap-color;
    border-color: $reap-color;
  }

  .text-primary {
    color: $reap-color !important;
  }

  .btn-primary.btn-outline {
    color: #fff !important;
  }

  .btn-outline {
    color: $reap-color !important;
    // background-color: transparent !important;;
    transition: all 0.5s !important;
    ;
  }

  .btn-primary {
    color: #fff;
    background-color: $reap-color;
    border-color: $reap-color;
  }

  .p-inputtext:enabled:focus,
  .p-inputtext:enabled:focus-visible,
  .p-inputtext:enabled:hover {
    box-shadow: none;
    border-color: $reap-color;
    outline: none;
  }

  .p-button {
    background: $reap-color;
    border: 1px solid $reap-color;
    border-radius: 0px 9px 9px 0px;
    color: white;
    width: 41px;
    height: 34px;

    span {
      font-size: 13px;
    }
  }

  .p-button:enabled:hover {
    background: $reap-color;
    border: 1px solid $reap-color;
  }

  .btn-primary.btn-outline:hover,
  .btn-success.btn-outline:hover,
  .btn-info.btn-outline:hover,
  .btn-warning.btn-outline:hover,
  .btn-danger.btn-outline:hover {
    color: $reap-color !important;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: $reap-color !important;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 3px #005e8580;
  }

  .btn-primary.btn-outline:hover {
    color: #ffffff;
    background-color: transparent !important;
    ;
    border-color: $reap-color !important;
    ;
  }

  body {
    background-color: #2f4050;
  }

  .nav-header {
    background-color: #2f4050;
  }

  .nav>li.active {
    border-left: 4px solid #00577b;
    background: #2f4050;
  }
}

html[data-theme='epic'] {

  .agreementRelated[aria-expanded="true"] {
    .panel-heading {
      color: #fff;
      background-color: $epic-color;
      border-color: $epic-color;
    }
  }

  .page-item.active .page-link {
    background-color: $epic-color;
    border-color: $epic-color;
  }

  .others[aria-expanded="true"] {
    .panel-heading {
      color: #fff;
      background-color: $epic-color;
      border-color: $epic-color;
    }
  }

  .product-price {
    background-color: $epic-color;
  }

  .label-primary,
  .badge-primary {
    background-color: $epic-color;
  }

  .btn-primary {
    color: #fff;
    background-color: $epic-color;
    border-color: $epic-color;

    &:hover {
      background-color: #24447f;
      border-color: #24447f;
      color: #FFFFFF;
    }
  }

  .text-primary {
    color: $epic-color;
  }

  .btn-primary.btn-outline {
    color: $epic-color !important;
  }

  .btn-outline {
    color: $epic-color !important;
    background-color: transparent;
    transition: all 0.5s;
  }


  .p-inputtext:enabled:focus,
  .p-inputtext:enabled:focus-visible,
  .p-inputtext:enabled:hover {
    box-shadow: none;
    border-color: $epic-color;
    outline: none;
  }

  .p-button {
    background: $epic-color;
    border: 1px solid $epic-color;
    border-radius: 0px 9px 9px 0px;
    color: white;
    width: 41px;
    height: 34px;

    span {
      font-size: 13px;
    }
  }

  .p-button:enabled:hover {
    background: $epic-color;
    border: 1px solid $epic-color;
  }

  .btn-primary.btn-outline:hover,
  .btn-success.btn-outline:hover,
  .btn-info.btn-outline:hover,
  .btn-warning.btn-outline:hover,
  .btn-danger.btn-outline:hover {
    color: #fff !important;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: $epic-color !important;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 3px #3092dc80;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary.focus {
    color: #ffffff;
    background-color: $epic-color;
    border-color: $epic-color;
  }

  body {
    background: linear-gradient(#0cf, #1b53bb) no-repeat;
    font-size: 13px;
    color: #676a6c;
    overflow-x: hidden;
    background-color: #1b53bb;
  }

  .nav-header {
    background-color: #03bdf7;
  }

  .metismenu>li.active {
    border-left: 4px solid #fff;
    background: transparent;
  }

  .nav-second-level li a {
    color: #fff;
  }

  .navbar-default .nav>li>a:hover {
    background-color: #24447f;
    color: white;
  }

  .nav>li.active {
    border-left: 4px solid #fff;
    background: #24447f;
  }

  .navbar-default .nav>li>a:focus {
    background-color: #24447f;
    color: white;
  }

  ul.nav-second-level {
    background: #24447f;
  }

  .tabs-container a {
    color: #495057
  }

  .metismenu>li>a {
    color: #ffffff;
  }

  .includesAndReferences[aria-expanded=true] .panel-heading,
  .referencesAndTypes[aria-expanded=true] .panel-heading,
  .agreementsAndProperty[aria-expanded=true] .panel-heading,
    .othersProject[aria-expanded=true] .panel-heading {
    color: #fff;
    background-color: $epic-color;
    border-color: $epic-color;
  }
}